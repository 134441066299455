import request from '@/utils/request'

export function getList(pageNum,pageSize,datas) {
    return request({
        url: '/product/list?pageNum=' + pageNum + "&pageSize=" + pageSize + '&datas=' + datas, 
        method: 'get'
    })
}


export function getList2(pageNum,pageSize,datas) {
    return request({
        url: '/product/list2?pageNum=' + pageNum + "&pageSize=" + pageSize + '&datas=' + datas, 
        method: 'get'
    })
}

export function addProduct(data) {
    return request({
        url: '/product/save', 
        method: 'post',
        data
    })
}

export function editProduct(id,data) {
    return request({
        url: '/product/edit?id=' + id, 
        method: 'post',
        data
    })
}


export function delProduct(id) {
    return request({
        url: '/product/delete?id=' + id, 
        method: 'post',
    })
}